let slideIndex = 1;
showSlides(slideIndex);

let next = document.querySelector("button.reviews-next");
let prev = document.querySelector("button.reviews-prev");

next.addEventListener("click", () => nextSlide(1));
prev.addEventListener("click", () => nextSlide(-1));

function nextSlide(number) {
  showSlides(slideIndex += number);
}

function showSlides(index) {
  let slides = document.querySelectorAll('.reviews__item');

  if (index > slides.length) slideIndex = 1;
  if (index < 1) slideIndex = slides.length;

  for (let i = 0; i < slides.length; i++) {
    slides[i].style.display = "none";
  }

  slides[slideIndex - 1].style.display = "flex";
}
